<template>
  <Unauthenticated>
    <div class="top-margin">

      <form v-on:submit.prevent="reset">
        <input type="password" name="password" v-model="password" placeholder="New Password"/>

        <input type="password" name="confirm-password" v-model="confirmPassword" placeholder="Confirm Password"/>

        <div class="error" v-if="errors">{{ errors }}</div>
        <button type="submit" value="Log In" class="primary w-100" @click.prevent.stop="reset">Reset</button>

      </form>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from '@/components/_layouts/Unauthenticated'
import {api} from "@/helpers/api";

export default {
  name: "PasswordReset",
  components: { Unauthenticated },
  data() {
    return {
      password: null,
      confirmPassword: null,
      errors: null

    }
  },
  methods: {
    async reset(){
      console.log(this.$route.params.token);

      if(this.password != this.confirmPassword){
        return this.errors = "Passwords do not match";
      }

      const res = await api.updatePass(this.$route.params.token, this.password)
      console.log(res)
      if(res === 'ok'){
        this.$notify({
          text: 'Your password has been reset',
          type: 'success',
        });
        this.$router.push({path: '/web/' + window.localStorage.getItem('company') + '-' + window.localStorage.getItem('site')})
      }else{
        this.errors = "There was an error resetting your password"
      }
    }
   
  }
}

</script>

<style scoped lang="scss">

form {
  border-radius: 0.5rem;
  max-width: 400px;
  padding: 2rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  img { width: 100%; }

  a{
    color: var(--text-colour);
    text-decoration: none;
  }

  //a:v
  button {
    margin-bottom: 1rem;
    margin-lefT: 0px !important;
    padding: 1rem;
    width: 100%;
    background-color: lavender;
    font-weight: bold;
    border-radius: 0.25rem;
    background-color: var(--action-colour);
    color: var(--primary-colour);
  }

  input, button { font-size: 1rem; border: none;   }
  input { font-size: 1rem; box-sizing: border-box; padding: 1em; width: 100%;    }

  p.or { text-align: center; }

  .btn-outline-info:hover { background-color: inherit; }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input, form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 2em;
    // max-width: none !important;

  }
}

</style>
